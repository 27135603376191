<template>
  <div>
    <img
      src="../assets/Abstract Design.svg"
      alt="Background"
      class="background-image"
    />
    <!-- Navbar -->
    <b-navbar
      type="dark"
      variant="dark"
      class="custom-navbar"
      toggleable="md"
      toggle-collapse
    >
      <b-navbar-brand href="#">
        <img
          src="../assets/logo-18.png"
          alt="Logo"
          class="logoImg"
          style="height: 30px; margin-left: 25px"
      /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="mobbview"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav >
        <b-navbar-nav class="mx-auto mobbview">
          <!-- Center Navbar Items -->
          <!-- <b-nav-item href="#">Home</b-nav-item>
          <b-nav-item href="#">Careers</b-nav-item>
          <b-nav-item href="#">Contact Us</b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto mobbview" >
          <!-- Right Aligned Navbar Items -->
          <b-nav-item
          
            href="#"
            @click="goto('contactUs')"
            style="margin-right: 15px"
            ><span style="color: white"
              ><b-button
                pill
                style="
                  background-color: #e50914;
                  color: #1c1c1c;
                  border: none !important;
                  font-weight: 600;
                  font-size: 15px;
                "
                @click="scrollToDiv"
                >Contact Us</b-button
              >
            </span></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- Centered content -->
    <b-container class="firstSectionMobile" style="margin-top: 100px">
      <b-row class="1stsection bigDesktop">
      <!-- <div class="row 1stsection"> -->
        <b-col md="4" style="margin-top: 100px">

        
        <!-- <div
          class="col-sm-5"
          
        > -->
          <p
            class="textmob"
            style="
              font-size: 50px;
              font-weight: 600;
              line-height: 0px;
              text-align: left;
              color: #e50914;
            "
          >
            <span style="color: white">Your Vision </span>
          </p>
          <p
            class="textmob"
            style="font-size: 50px; text-align: left; font-weight: 600"
          >
            <span style="color: #e50914">Our Expertise!</span>
          </p>
          <p
            class="textmob"
            style="text-align: left; color: white; font-size: 15px"
          >
            Unlock Digital Potential, Elevate Your Experience, <br />
            Transform Your Business Landscape, and Ignite Growth 
            with Our Digital Solutions.
          </p>
          <b-button
            class="textmob"
            size="sm"
            style="
              background-color: #e50914;
              float: left;
              margin-top: 20px;
              padding: 5px 23px 5px 23px !important;
              border: none !important;
              border-radius: 10px;
            "
          >
            <span
              style="font-size: 18px; color: black; font-weight: 600"
              @click="scrollToDiv2()"
              >Explore Our Services</span
            ></b-button
          >
        </b-col>
        <!-- </div> -->
        <b-col md="8"
        >

        
        <!-- <div
          class="col-sm-7"
          
        > -->
          <carousel-3d
            :width="350"
            :height="400"
            :autoplay="true"
            :autoplay-timeout="5000"
            :display="3"
          >
            <slide :index="0">
              <img
                :src="require('@/assets/card1.svg')"
                alt="First Image"
                class="first-image mobile-image"
              />
              <!-- <img :src="svgPath"  style="width: 30%;display: flex;justify-content: left;align-items: left;position: absolute;"> -->
            </slide>
            <slide :index="1">
              <img
                :src="require('@/assets/card2.svg')"
                alt="First Image"
                class="first-image"
              />
            </slide>
            <slide :index="2">
              <img
                :src="require('@/assets/card3.svg')"
                alt="First Image"
                class="first-image"
              />
            </slide>
          </carousel-3d>
          <img
            src="../assets/Group.png"
            class="triangle-top-right"
            alt="Top Left Triangle"
          />
        </b-col>
        <!-- </div> -->
      <!-- </div> -->
    </b-row>
      <div
        class="row 2ndsection firstSectionMobile"
        id="targetDiv"
        style="margin-top: 50px"
      >
        <div
          class="col-sm-5"
          
        >
          <h1
            style="
              text-align: left;
              margin-bottom: 20px;
              font-size: 38px;
              color: white;
            "
          >
            About <span style="color: #e50914; font-size: 38px">Us</span>
          </h1>
          <p style="text-align: left; color: white; font-size: 15px">
            18arts stands as a vibrant hub where creativity meets technology. As
            a dynamic team of young, dedicated professionals, we are fueled by
            passion and committed to excellence in every endeavour. At the heart
            of our mission is a desire to revolutionise the digital landscape,
            integrating innovative solutions that drive sustainable growth and
            transform businesses.
          </p>
          <p style="text-align: left; color: white; font-size: 15px">
            At 18arts, we understand that the digital world is constantly
            evolving, and staying ahead requires a unique blend of accuracy,
            trust, and unwavering dedication to our clients' needs. Whether it's
            crafting smart business strategies, delivering top-notch digital
            marketing services, or creating visually compelling media, our goal
            is to ensure your vision not only takes shape but soars
          </p>
        </div>
        <div class="col-sm-1"></div>
        <div
          class="col-sm-6 contact-column"
          
        >
          <h2 class="contact-heading">Contact Us</h2>
          <p style="font-size: 13px">
            Spin Your Brand into the Digital Future with us <br />
            Let's build together!
          </p>
          <form action="https://formspree.io/f/mdoqvobz" method="POST">
            <div class="row" style="margin-top: 20px">
              <div class="col-sm-1"></div>
              <div class="col-sm-5">
                <div class="input-group mb-3">
                  <label>
                    <!-- Full Name -->
                    <input
                      type="text"
                      name="Full Name"
                      placeholder="Full Name"
                      class="inputmob"
                      style="
                        padding: 7px;
                        width: 100%;
                        background-color: #262626;
                        border: 0.5px solid #403f3f;
                        border-radius: 10px;
                        color: white;
                      "
                    />
                  </label>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="input-group mb-3">
                  <label>
                    <!-- Email ID -->
                    <input
                      type="email"
                      name="Email ID"
                      placeholder="Email"
                      class="inputmob"
                      style="
                        padding: 7px;
                        width: 100%;
                        background-color: #262626;
                        border: 0.5px solid #403f3f;
                        border-radius: 10px;
                        color: white;
                      "
                    />
                  </label>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>

            <div class="row" style="margin-top: 20px">
              <div class="col-sm-1"></div>
              <div class="col-sm-5">
                <div class="input-group mb-3">
                  <label>
                    <!-- Mobile Number -->
                    <input
                      type="number"
                      name="Mobile Number"
                      placeholder="Mobile Number"
                      class="inputmob"
                      style="
                        padding: 7px;
                        width: 100%;
                        background-color: #262626;
                        border: 0.5px solid #403f3f;
                        border-radius: 10px;
                        color: white;
                      "
                    />
                  </label>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="input-group mb-3">
                  <label>
                    <!-- Services -->
                    <select
                      name="Services"
                      class="inputmob2"
                      placeholder="Select Service"
                      style="
                        padding: 10px;
                        width: 100%;
                        background-color: #262626;
                        border: 0.5px solid #403f3f;
                        border-radius: 10px;
                        color: white;
                      "
                    >
                      <option
                        value="Select Service"
                        disabled
                        selected
                        style="background-color: #262626; color: white"
                      >
                        Select Service
                      </option>
                      <option
                        value="Digital Marketing"
                        style="background-color: #262626; color: white"
                      >
                        Digital Marketing
                      </option>
                      <option
                        value="Web & Media Services"
                        style="background-color: #262626; color: white"
                      >
                        Web & Media Services
                      </option>
                      <option
                        value="Architecture & Interior Services"
                        style="background-color: #262626; color: white"
                      >
                        Architecture & Interior Services
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>

            <div class="row" style="margin-top: 20px">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <button
                  type="submit"
                  style="
                    background-color: #e50914;
                    margin-top: 20px;
                    padding: 10px 80px;
                    border: none;
                    border-radius: 10px;
                  "
                >
                  <span style="font-size: 18px; color: black; font-weight: 600"
                    >Submit</span
                  >
                </button>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </form>
          <!-- Input fields -->
        </div>
      </div>
      <div
        class="row 3rdsection"
        style="margin-top: 50px"
       
        id="targetDiv2"
      >
        <p
          style="
            text-align: left;
            margin-top: 50px;
            margin-bottom: 30px;
            color: white;
            font-size: 38px;
          "
        >
          Our <span style="color: #e50914; font-size: 38px">Services</span>
        </p>
        <div
          class="col-sm-3"
          
        >
          <b-card
            style="background-color: #1c1c1c; border: 0.4px solid #323232"
            class="mb-2"
          >
            <div class="row">
              <!-- <div class="col-sm-1"></div> -->
              <div class="col-sm-12" v-if="but1 === true">
                <b-card
                  @click="button1"
                  style="
                    background-color: #1c1c1c;
                    border: 0.5px solid #e50914;
                    transform: scale(1.04);
                  "
                >
                  <b-card-text style="float: left">
                    <span
                      style="
                        font-size: 16px;
                        font-weight: 600;
                        color: #e50914;
                        cursor: pointer;
                      "
                      >Digital Marketing</span
                    >
                  </b-card-text>
                </b-card>
                <!-- <b-button href="#"  class="mb-4" @click="button1" style="background-color: #1C1C1C; border: 0.4px solid #323232;padding: 5px 40px 5px 40px;"> <span style="font-size: 12px;">Digital Marketing</span> </b-button> -->
              </div>
              <div class="col-sm-12" v-else>
                <b-card
                  @click="button1"
                  style="background-color: #1c1c1c; border: 0.5px solid #403f3f"
                >
                  <b-card-text style="float: left">
                    <span
                      style="
                        font-size: 15px;
                        font-weight: 600;
                        color: white;
                        cursor: pointer;
                      "
                      >Digital Marketing</span
                    >
                  </b-card-text>
                </b-card>
                <!-- <b-button href="#"  class="mb-4" @click="button1" style="background-color: #1C1C1C; border: 0.4px solid #323232;padding: 5px 40px 5px 40px;"> <span style="font-size: 12px;">Digital Marketing</span> </b-button> -->
              </div>
              <!-- <div class="col-sm-1"></div> -->
            </div>
            <div class="row" style="margin-top: 20px">
              <!-- <div class="col-sm-1"></div> -->
              <div class="col-sm-12" v-if="but2 === true">
                <b-card
                  @click="button2"
                  style="background-color: #1c1c1c; border: 0.5px solid #e50914"
                >
                  <b-card-text style="float: left">
                    <span
                      style="
                        font-size: 16px;
                        font-weight: 600;
                        color: #e50914;
                        cursor: pointer;
                      "
                      >Web and Media services</span
                    >
                  </b-card-text>
                </b-card>
                <!-- <b-button href="#"  class="mb-4" @click="button2" style="background-color: #1C1C1C; border: 0.4px solid #323232;padding: 5px 18px 5px 22px;"><span style="font-size: 12px;">Web and Media services</span></b-button> -->
              </div>
              <div class="col-sm-12" v-else>
                <b-card
                  @click="button2"
                  style="background-color: #1c1c1c; border: 0.5px solid #403f3f"
                >
                  <b-card-text style="float: left">
                    <span
                      style="
                        font-size: 15px;
                        font-weight: 600;
                        color: white;
                        cursor: pointer;
                      "
                      >Web and Media services</span
                    >
                  </b-card-text>
                </b-card>
                <!-- <b-button href="#"  class="mb-4" @click="button2" style="background-color: #1C1C1C; border: 0.4px solid #323232;padding: 5px 18px 5px 22px;"><span style="font-size: 12px;">Web and Media services</span></b-button> -->
              </div>
              <!-- <div class="col-sm-1"></div> -->
            </div>

            <div class="row" style="margin-top: 20px">
              <!-- <div class="col-sm-1"></div> -->
              <div class="col-sm-12" v-if="but3 === true">
                <b-card
                  @click="button3"
                  style="background-color: #1c1c1c; border: 0.5px solid #e50914"
                >
                  <b-card-text style="float: left">
                    <span
                      style="
                        font-size: 17px;
                        font-weight: 600;
                        color: #e50914;
                        cursor: pointer;
                      "
                      >Architecture Services</span
                    >
                  </b-card-text>
                </b-card>
                <!-- <b-button href="#"  class="mb-1" @click="button3" style="background-color: #1C1C1C; border: 0.4px solid #323232;"><span style="font-size: 13px;">Architectural Solutions</span> </b-button> -->
              </div>
              <div class="col-sm-12" v-else>
                <b-card
                  @click="button3"
                  style="background-color: #1c1c1c; border: 0.5px solid #403f3f"
                >
                  <b-card-text style="float: left">
                    <span
                      style="
                        font-size: 15px;
                        font-weight: 600;
                        color: white;
                        cursor: pointer;
                      "
                      >Architecture Services</span
                    >
                  </b-card-text>
                </b-card>
                <!-- <b-button href="#"  class="mb-1" @click="button3" style="background-color: #1C1C1C; border: 0.4px solid #323232;"><span style="font-size: 13px;">Architectural Solutions</span> </b-button> -->
              </div>
              <!-- <div class="col-sm-1"></div> -->
            </div>
          </b-card>
        </div>
        <div class="col-sm-9" v-if="but1 === true">
          <div class="row">
            <!-- <h1 style="text-align: left;margin-top:50px;margin-bottom: 30px;">Our <span style="color: #e50914">Services</span> </h1> -->

            <div class="col-sm-6">
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 206px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Performance Marketing
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Boost your brand with 18arts Performance Marketing. We
                    handle everything from targeted ads to pay-per-click and
                    social media campaigns, ensuring you get the best return on
                    investment and successful strategic campaigns.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 206px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Search Engine Optimization
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Get noticed in search engines with 18arts. Our SEO experts
                    will fine-tune your website and content to connect better
                    with your audience, helping you stay ahead with strategies
                    that drive continuous success.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 206px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Reputation Management
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Protect and enhance your online reputation with 18arts. We
                    manage reviews, handle social media perceptions, and
                    strategize for crisis managment, helping you maintain a
                    positive presence online.
                  </p>
                </div>
              </b-card>
            </div>
            <div class="col-sm-6">
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 206px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Social Media Management
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Maximize your brand's social reach with 18 Arts, a top
                    Digital Marketing Agency. Tailored strategies, engaging
                    content, vibrant communities, and data-driven insights
                    elevate your brand across platforms for lasting impact.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 206px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Content and Strategy
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Develop your brand's story with 18arts. We craft strategic
                    narratives and SEO-optimized content to engage customers and
                    drive sales, partnering with you for lasting digital market
                    success.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 206px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Market Research and Analytics
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Utilize market research and analytics to boost your digital
                    marketing efforts. With 18arts, gain valuable insights,
                    refine strategies, and increase your return on investment
                    for greater business growth.
                  </p>
                </div>
              </b-card>
            </div>
          </div>
        </div>
        <div class="col-sm-9" v-if="but2 === true">
          <div class="row">
            <!-- <h1 style="text-align: left;margin-top:50px;margin-bottom: 30px;">Our <span style="color: #e50914">Services</span> </h1> -->

            <div class="col-sm-6">
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 273px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Website design and development
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We understand that a powerful online presence begins with a
                    great website. Our approach combines aesthetics with
                    functionality, ensuring that each site we create is visually
                    striking and easy to navigate. We place a strong emphasis on
                    User Experience (UX) and User Interface (UI) to make sure
                    that every visitor's interaction is seamless and converts
                    them into loyal customers.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 273px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Graphic Designing
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We at 18arts love to tell stories visually. Our team is
                    skilled in creating designs that catch the eye and
                    communicate your brand's message clearly to your audience,
                    helping your brand stand out.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 273px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Branding
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We offer complete branding services tailored to showcase
                    what makes your business unique. From designing your logo to
                    developing a brand strategy, we work with you to create a
                    strong identity that resonates with your audience.
                  </p>
                </div>
              </b-card>
            </div>
            <div class="col-sm-6">
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 273px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      E-Commerce Solutions
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Launch and run a successful online store. We ensure every
                    part of your e-commerce site, from easy navigation to secure
                    checkout, works perfectly to keep your customers happy and
                    your sales growing.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 273px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Video & Audio - Production House
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We produce high-quality video and audio that truly engages
                    viewers and listeners. Our team manages the entire
                    production process, ensuring that the final product is as
                    impactful as it is creative, from the initial idea to the
                    final cut.
                  </p>
                </div>
              </b-card>

              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 273px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      2D/3D animations
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    With 18arts, bring your ideas to life through engaging 2D
                    and 3D animations. Ideal for everything from explainer
                    videos to social media posts, our animations are crafted to
                    capture attention and clearly convey your message.
                  </p>
                </div>
              </b-card>
            </div>
          </div>
        </div>
        <div class="col-sm-9" v-if="but3 === true">
          <div class="row">
            <!-- <h1 style="text-align: left;margin-top:50px;margin-bottom: 30px;">Our <span style="color: #e50914">Services</span> </h1> -->

            <div class="col-sm-6">
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 296px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Interior Design
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Interior design is more than just making spaces beautiful.
                    It's about creating environments that reflect your
                    personality, are functional, and make you feel good. Our
                    team is passionate about designing spaces that match your
                    lifestyle and preferences perfectly, ensuring every detail
                    contributes to a cohesive and enjoyable environment. Our
                    ideas are inspired by LivSmart and WorkSmart products from
                    Ebco.
                  </p>
                </div>
              </b-card>

              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 296px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Project management
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We are proud of our efficient project management, ensuring
                    every project is delivered on time, within budget and with
                    the highest quality. Our approach involves rigorous planning
                    and quality checks, with ongoing communication to keep you
                    informed and engaged from start to finish and beyond.
                  </p>
                </div>
              </b-card>
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 296px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Structural Designs
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We provide innovative and reliable structural design
                    services tailored to your project's specific needs. Our
                    structural engineers combine technical expertise with
                    creative solutions to ensure your structures are not only
                    safe and durable but also optimized for efficiency and
                    cost-effectiveness.
                  </p>
                </div>
              </b-card>
            </div>
            <div class="col-sm-6">
              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 296px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      3D Visualization/Renders
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Our team excels in creating realistic 3D visualizations and
                    renderings that bring your design ideas to life. This
                    technology allows you to see and explore your space before
                    it is built, ensuring it meets your expectations and needs,
                    whether you're a homeowner, architect, or designer.
                  </p>
                </div>
              </b-card>

              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 296px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      Architectural drawings
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    Our team produces precise architectural drawings that form
                    the foundation of your design project. Using the latest
                    software and techniques, we create detailed plans,
                    elevations, and sections that are essential for accurate
                    construction and implementation.
                  </p>
                </div>
              </b-card>

              <b-card
                style="
                  background-color: #1c1c1c;
                  border: 0.4px solid #323232;
                  min-height: 296px;
                "
                class="mb-2 hoveron"
              >
                <div class="row">
                  <div class="col-sm-10">
                    <p
                      style="
                        font-size: 18px;
                        color: white;
                        float: left;
                        margin-left: 5px;
                      "
                    >
                      AR/VR
                    </p>
                  </div>
                  <div class="col-sm-2">
                    <img src="../assets/Icon.png" alt="" />
                  </div>
                </div>
                <div class="row">
                  <p
                    style="
                      font-size: 15px;
                      color: white;
                      text-align: left;
                      margin-left: 5px;
                    "
                  >
                    We use advanced augmented reality (AR) and virtual reality
                    (VR) technology to offer a new way of experiencing and
                    interacting with design spaces. This immersive technology
                    allows you to visualize and walk through your future space,
                    enhancing decision-making and engagement.
                  </p>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row 4thsection"
        style="
          margin-top: 20px;
          margin-bottom: 30px;
          text-align: center;
          position: relative;
        "
      >
        <img
          src="../assets/CTA.png"
          alt=""
          style="height: 50%; display: block; margin: 0 auto"
        />
        <p
          class="inputcard"
          style="
            color: #e50914;
            font-size: 35px;
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          Your Partner in Progress, Offering Limitless Possibilities
        </p>
      </div>
    </b-container>

    <!-- <marquee width="100%" direction="left" height="100px" scrollamount="12">
      <p style="font-size: 50px; color: white; font-weight: 800">
        <span>Digital Marketing</span><span style="color: #e50914"> • </span>
        <span>Social Media</span><span style="color: #e50914"> • </span>
        <span>Strategy</span><span style="color: #e50914"> • </span>
        <span>Website</span><span style="color: #e50914"> • </span>
        <span>E-commerce</span><span style="color: #e50914"> • </span>
        <span>Graphic Design</span><span style="color: #e50914"> • </span>
        <span>Interior Design</span><span style="color: #e50914"> • </span>
        <span>3D Renders</span><span style="color: #e50914"> • </span>
        <span>Architectural Drawings</span>
      </p>
    </marquee> -->
    <div
      class="row 5thsection"
      style="background-color: #1c1c1c; padding: 20px"
    >
      <div class="col-sm-12 text-center mb-3">
        <img
          src="../assets/logo-18.png"
          alt="Logo"
          style="width: 20%"
          class="imgmob"
        />
      </div>
      <div class="col-sm-12">
        <hr style="background-color: white" />
      </div>
      <div class="col-sm-12 text-center text-white mb-3">
        <p>
          <span><img src="../assets/Email.png" alt="" /></span>
          <span style="margin-left: 5px"><a href="mailto:hello@18arts.in" style="text-decoration: none;color: white;">hello@18arts.in</a></span>
          <span style="margin-left: 20px">
            <img src="../assets/Phone.png" alt="" />
          </span>
          <span style="margin-left: 5px;"><a href="tel:+918310692519" style="text-decoration: none;color: white;">+91 8310692519</a> </span>
          <span style="margin-left: 20px">
            <img src="../assets/Map.png" alt="" />
          </span>
          <span style="margin-left: 5px">Chandra Layout, Bengaluru</span>
        </p>
      </div>
      <div class="col-sm-12">
        <hr style="background-color: white" />
      </div>

      <div class="col-sm-12">
        <b-card
          class="text-center"
          style="
            background-color: black;
            border-radius: 100px;
            margin-bottom: 50px;
          "
        >
          <b-card-text>
            <div class="row justify-content-center align-items-center">
              <div class="col-sm-4 mb-2 mb-sm-0">
                <a
                  href="https://www.instagram.com/18arts_studio?igsh=MTlnOGtmMzVzdjhycg=="
                  ><img
                    src="../assets/Insta.png"
                    alt=""
                    style="width: 9%; margin-right: 10px"
                /></a>
                <a href="https://www.facebook.com/18arts.in?mibextid=ZbWKwL"
                  ><img
                    src="../assets/Facebook.png"
                    alt=""
                    style="width: 9%; margin-right: 10px"
                /></a>
                <a
                  href="https://x.com/18arts_studio?t=fDfmpG8Nz5aEAA_NGiwmBQ&s=09&mx=2"
                  ><img
                    src="../assets/Twitter.png"
                    alt=""
                    style="width: 9%; margin-right: 10px"
                /></a>
                <a href="https://www.linkedin.com/company/18arts-studio/"
                  ><img
                    src="../assets/LinkedIn.png"
                    alt=""
                    style="width: 9%; margin-right: 10px"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UCYYOzC_PJfuW_NYl40mRK6Q"
                  ><img
                    src="../assets/youtube.svg"
                    alt=""
                    style="width: 9%; margin-right: 10px"
                /></a>
              </div>
              <div class="col-sm-4 mt-2">
                <p class="text-white">
                  © 2024 18arts Studio. All Rights Reserved
                </p>
              </div>
              <div class="col-sm-4 mt-2">
                <p class="text-white">Privacy Policy | Terms of service</p>
              </div>
            </div>
          </b-card-text>
        </b-card>
           
      </div>
    </div>
    <!-- Decorative triangles -->
    <!-- <img src="../assets/logo.png" class="triangle-top-left" alt="Top Left Triangle">
    <img src="../assets/logo.png" class="triangle-top-right" alt="Top Right Triangle">
    <img src="../assets/logo.png" class="triangle-bottom-center" alt="Bottom Center Triangle"> -->
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Vue from "vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import VueMarqueeSlider from "vue-marquee-slider";
Vue.use(VueMarqueeSlider);
Vue.use(Carousel3d);
export default {
  name: "Home",
  data: {
    slides: 7,
  },
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      but1: true,
      but2: false,
      but3: false,
    };
  },
  mounted() {
    AOS.init();
  },
  methods: {
    scrollToDiv() {
      // Use JavaScript to scroll to the element
      const element = document.getElementById("targetDiv");
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    },
    scrollToDiv2() {
      // Use JavaScript to scroll to the element
      const element = document.getElementById("targetDiv2");
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    },
    button1() {
      this.but1 = true;
      this.but2 = false;
      this.but3 = false;
    },
    button2() {
      this.but1 = false;
      this.but2 = true;
      this.but3 = false;
    },
    button3() {
      this.but1 = false;
      this.but2 = false;
      this.but3 = true;
    },
  },
};
</script>

<style>
.form-control::placeholder {
  color: white;
}
.firstSectionMobile {
  margin-top: 100px !important;
}

@media (max-width: 1200px){
  .bigDesktop{

  }
}
@media (max-width: 767px) {
  /* Adjust the max-width as per your mobile breakpoint */
  .firstSectionMobile {
    margin-top: 0px !important;
  }
  .textmob {
    text-align: center !important;
    float: none !important;
  }
  .inputmob {
    margin-left: 35px !important;
    width: 135% !important;
  }
  .inputmob2 {
    margin-left: 34px  !important;
    width: 120% !important;
  }
  .inputcard {
    font-size: 13px !important;
  }
  .imgmob {
    width: 50% !important;
  }
  .mobbview{
    display: none !important;
  }
  .logoImg{
    margin-left: 87px !important;
  }
}

body {
  overflow-x: hidden !important;
}
.cardDeckers {
  min-height: 170px;
}
@import "@/assets/font.css";
/* Custom styles for the contact column */
.contact-column {
  background-image: url("../assets/Sign\ Up\ \(1\).png");
  background-size: cover;
  background-position: center;
  position: relative; /* Set position relative to allow absolute positioning of content */
  color: white; /* Set text color to white for better visibility on the image */
  padding: 20px; /* Add padding to the column */
}

/* Custom styles for the contact heading */
.contact-heading {
  color: #e50914;
  font-size: 28px; /* Adjust font size as needed */
  margin-bottom: 20px; /* Add some space below the heading */
}

/* Custom styles for the input fields */
.input-group {
  margin-bottom: 20px; /* Add some space between input fields */
}
.custom-navbar {
  width: 80%;
  margin: 0 auto; /* Center the navbar */
  z-index: 1;
  border-radius: 50px;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  z-index: -1;
}
@media (max-width: 768px) {
  .background-image {
    width: 100%; /* Width for mobile view */
  }
}
/* Triangle CSS */
.triangle-top-left,
.triangle-top-right,
.triangle-bottom-center {
  position: absolute;
}

.triangle-top-left {
  top: 0;
  left: 0;
}

.triangle-top-right {
  top: 217px;
  right: 55px;
  z-index: -1;
  width: 22%;
}

.carousel-3d-slide {
  border-color: none !important;
  border-color: none !important;
  border-style: none !important;
  background-size: none !important;
  background-color: rgba(255, 255, 255, 0) !important; 
}

.triangle-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.hoveron:hover {
  transform: scale(1.02);
}
</style>
